import { Component, SimpleChanges, OnInit, Input, ViewChild } from '@angular/core';
import * as $ from 'jquery';
window["$"] = $;
import 'datatables.net';
import 'datatables.net-bs4';
import 'bootstrap';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import { environment } from '../../environments/firebaseConfiguration';
import { doc, deleteDoc, updateDoc, collection, where,query, orderBy  } from "firebase/firestore";
import { FormGroup, FormControl, Validators} from '@angular/forms'
import { TransactionResult } from '@firebase/database';
import { FirebaseService } from '../firebase.service';
import { ImageViewerComponent } from 'ngx-image-viewer';

@Component({
  selector: 'app-transaction-table',
  templateUrl: './transaction-table.component.html',
  styleUrls: ['./transaction-table.component.scss']
}) 
export class TransactionTableComponent implements OnInit  {

  @ViewChild('imageViewer') viewer !: ImageViewerComponent
  @ViewChild('imageViewerOnly') viewerOnly !: ImageViewerComponent
  @Input() item = ''; // decorate the property with @Input()
  @Input() transactionId = ''
  typeahead: FormControl = new FormControl();
  // lotNumber: string[] = ['BAXUID', 'ABCDERL', 'ZPOUASFJK', 'MASLKDL']
  lotNumber: string[];
  partNo: string;
  suggestions: string[] = [];

  // Initialize Firebase
app = initializeApp(environment.firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
db = getFirestore(this.app);
  formdata;
  img_url = ["../../assets/images/transaction_images/gold-cap.jpg"]
  img_url_only = ["../../assets/images/transaction_images/gold-cap.jpg"]
  part_no = '';
  lot_no = '';
  doc_id = '';
  imageIndex =0;
  imageIndexOnly =0;
  deleteRecord = null;
  deleteIndex = null;
  fullscreen: boolean = false;
  fullscreenImage: boolean = false;
  result =[];
  scale = 1;
  transId:any;
  constructor(private fireService: FirebaseService) {
  }
  ngOnInit() { 
        this.formdata = new FormGroup({
          partNumber: new FormControl(""),
          lotNumber: new FormControl("")
      });

      this.transId = this.item
      const citiesRef = collection(this.db, `${environment.collectionName}/${this.transId}/products/`);
      const q = query(citiesRef, orderBy("created_on", 'asc'));
      onSnapshot(q, (doc) => {
        this.result = [];
        doc['docs'].forEach((a,i)=>{
          let tmpData = {'sr_no':i+1, 'doc_id': a.id, 'part_no': a.data()['part_no'], 'lot_no': a.data()['lot_no'], 'remark': a.data()['remark'], 'verification': a.data()['master_data'], 'img_url': a.data()['img_url'], 'created_on' : a.data()['created_on']}
          tmpData['created_on'] = this.objToDate(tmpData['created_on'])
          this.result.push(tmpData)
        })
       
        // this.reInitDataTable();
        this.reloaddata();
    });
  }

  objToDate(obj) {
    let result = new Date(0);
    result.setSeconds(obj.seconds);
    result.setMilliseconds(obj.nanoseconds/1000000);
    const latestres = Date.parse(''+result);
    return latestres;
  }

  ngOnChanges(changes: SimpleChanges){
    this.fullscreen = !this.fullscreen;
  }

  imageModalOpen(y){
    this.viewerOnly.config.btnShow.rotateClockwise = false;
    this.viewerOnly.fullscreen = false;
    this.viewerOnly.config.wheelZoom = true;
    this.viewerOnly.style={
      "msTransform": "",
      "oTransform": "",
      "transform": "",
      "webkitTransform": ""
    }
    this.viewerOnly.reset();
    this.img_url_only =y['img_url'];
  }

  modalOpen(x){
    // this.getSuggestions();
    this.viewer.config.btnShow.rotateClockwise = false;
    this.viewer.fullscreen = false;
    this.viewer.config.wheelZoom = true;
    this.viewer.style={
      "msTransform": "",
      "oTransform": "",
      "transform": "",
      "webkitTransform": ""
    }
    this.viewer.reset();
    this.img_url =x['img_url'];
    this.part_no = x['part_no'];
    this.lot_no = x['lot_no'];
    this.doc_id = x['doc_id'];
    this.partNo= this.part_no;
    this.formdata = new FormGroup({
      partNumber: new FormControl(this.part_no),
      lotNumber: new FormControl(this.lot_no),
      doc_id: new FormControl(this.doc_id)
   });
   this.suggestions =[];
   
   if(!x['verification']){
    this.fireService.getSimilarLots(this.lot_no).subscribe((val)=>{
      if(val.length > 0){
        this.suggestions=val
      }else{
        this.suggestions =[];
      }
    })
    }
  }

  removePill(i){
    console.log("remvoe")
    // let tmplist = [];
    // tmplist = this.suggestions;
    // console.log("Suggestion Length before",tmplist.length)
    // tmplist.forEach((el,i)=>{
    //   if(el==lot_no){
    //     delete tmplist[i]
    //   }
    // })
    // this.suggestions = tmplist;
    // console.log("Suggestion Length after",tmplist.length)
    this.suggestions.forEach((element,index)=>{
      // if(element===i) delete this.suggestions[index];
      if(element===i){
        // this.suggestions = delete this.suggestions[index];
        this.suggestions.splice(index,1)
      }
   }); 
   console.log("Suggestion Length",this.suggestions.length)   
  }
  
  modalDelete(record){
    this.deleteRecord = record;
  }

  cancelDelete(){
    this.deleteRecord = null;
    this.deleteIndex = null;
  }

  delete(y){
    deleteDoc(doc(this.db, `${environment.collectionName}/${this.transId}/products`, y['doc_id']));
    // this.reInitDataTable();
    this.reloaddata();
  }

  // reInitDataTable(){
  //     $(document).ready(function() {
  //       $('#myTable').DataTable({
  //         order: [[ 0, 'asc' ],[8, 'desc']],
  //     });
  //       })
  //   };  


    reloaddata() //data reload function
    {
      var datatable = $('#myTable').DataTable()
        //datatable reloading 
          datatable.destroy();
          // setTimeout(()=>{
          $(document).ready(function() {
            $('#myTable').DataTable({
              order: [[0, 'desc']],
              info : false,
              stripeClasses: [],
              columnDefs: [
                  { orderable: true, className: 'reorder', targets: [0,2,3] },
                  { orderable: false, targets: '_all' }
              ],
              destroy:true
          })
            })
          // },1000)
            
    }  

  saveModal(data) {
    const doc_id = data['doc_id']
    // const ref = doc(collection(this.db, `transctions/${this.transId}/products/`), doc_id);
    const ref = doc(this.db, `${environment.collectionName}/${this.transId}/products/`, doc_id);
    updateDoc(ref, {
      lot_no: data['lotNumber'].toUpperCase().trim(),
      part_no:data['partNumber'].toUpperCase().trim(),
    });
    this.fireService.ValidateData(data['lotNumber'].toUpperCase().trim(),data['partNumber'].toUpperCase().trim(),this.transId,doc_id)
  } 

  getSuggestions(){
    this.lotNumber =[] // post the modal close again modal open need to get these data
    this.suggestions = this.lotNumber

    // the below snippet might get used for aut populating the code
    // .filter(c => c.startsWith(event))
    // .slice(0, 5);
    // if(!event){
    //   this.suggestions=[''];
    // }
  }

  //on click on the pill container
  setLotNoVal(i){
  //   this.suggestions.forEach((element,index)=>{
  //     if(element===i) delete this.suggestions[index];
  //  });
  if(this.part_no == this.partNo){
    this.formdata = new FormGroup({
      lotNumber: new FormControl(i.Lot_Number),
      partNumber: new FormControl(this.part_no),
      doc_id: new FormControl(this.doc_id)
   });
  }else{
    this.formdata = new FormGroup({
      lotNumber: new FormControl(i.Lot_Number),
      partNumber: new FormControl(this.partNo),
      doc_id: new FormControl(this.doc_id)
   });
  }
  }

  //on click on the close button within the pill container
  closeSuggestion(x) {
    this.suggestions.forEach((element,index)=>{
      if(element===x) delete this.suggestions[index];
   });
  }  
  
  fnLPAPrint(data){
    console.log("part_no==="+data.part_no);
    console.log("lot_no==="+data.lot_no);
    this.fnLabelPrint(data.part_no, data.lot_no);
  }

  fnLabelPrint(partno,ctrlno){
    console.log("Part_Lot_Details==="+partno +ctrlno);
    var url = "labelprint:"+'&p'+partno+'&c'+ctrlno;
    window.open(url);
  }

}
